import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import downArrow from "../../assets/images/downArrow.png"
const ServiceCards = ({ services }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate


  const handleOrderClick = () => {
    window.open('https://vision-med.eu/sign-in', '_blank'); // Open in a new tab
  };


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Function to calculate price for discount
  const calculatePriceForDiscount = (basePrice, code) => {
    let discount = (code == 'SV-EDXL-2488-0008') ? 0.1550 : 0.3175;
    return Math.round(basePrice * (1 + discount));
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8  mt-16">
      <div className="p-5 rounded-lg shadow-lg text-left bg-[#FAFAFA] border border-gray-300 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center mb-8 ">
            <h2 className="text-2xl md:text-[27px] font-opensans font-medium text-[#4D4D4D]">
              Second Opinion at a German hospital
            </h2>
            <button
              className="md:hidden ml-2 focus:outline-none w-[3rem] mt-[-1rem] h-8 flex justify-center items-center border border-[#BABABA] rounded-full"
              onClick={toggleDropdown}
            >
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </button>
          </div>
          <p className="text-justify text-4xl md:text-[40px] font-opensans leading-[3.500rem] font-medium mb-8 text-[#4D4D4D]">
            Approx. 2000€
          </p>
          {(isOpen || window.innerWidth >= 768) && (
            <div>
              <p className="mb-4 text-[17px] text-gray-600 flex">
                <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 mt-1" />
                Delays in seeing cancer specialists can impact outcomes
              </p>
              <p className="mb-4 text-[17px] text-gray-600 flex">
                <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 mt-1" />
                Stress and anxiety from delays and complexities for patients and families
              </p>
              <p className="mb-4 text-[17px] text-gray-600 flex">
                <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 mt-1" />
                Missing overview of available doctors & timeslots increases stress
              </p>
              <p className="mb-4 text-[17px] text-gray-600 flex">
                <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 mt-1" />
                Complex search, communication, travel planning
              </p>
              <p className="mb-4 text-justify text-[17px] text-gray-600 flex">
                <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 mt-1" />
                Financial burden: approx. €2000 incl. travel to Germany
              </p>
            </div>
          )}
        </div>
        {/* <button
          className="w-full py-4   rounded-lg mt-4 bg-[#05BDFB] text-white hover:bg-[#0399D0] self-center"
          onClick={handleOrderClick}
        >
          Order
        </button> */}

      </div>
      {services.slice(0, 2).map((service, index) => (
        <div
          key={service._id}
          className="p-5 rounded-lg shadow-lg text-left bg-[#386DBD] text-white border border-blue-600 flex flex-col justify-between"
        >
          <div>
            <h2 className=" text-2xl md:text-[27px] font-opensans font-medium mb-8 text-white">
              {service.name.en}
            </h2>
            <p className="text-justify   text-[2.5rem] md:text-[40px]  font-opensans font-medium mb-2 text-white">
              € {service.price.value}
            </p>
            <div className="flex space-x-2 mt-2 mb-8">
              {/* Save 52% Text */}
              <span className="bg-[#4989E8] md:text-[18px] px-2 py-1 rounded-md">
              Limited Offer
              </span>
              {/* Limited Offer Text */}
              <span className="md:text-[18px]  text-white px-2 py-1">
              Usual Price: <span class=" font-bold line-through">€ {calculatePriceForDiscount(service.price.value, service.code)}</span>
              </span>
            </div>
            <p className="mb-8 text-white">
              {service.description.en}
            </p>
            {service.options.length > 0 && (
              <div className="text-justify  mb-5 mt-2">
                {service.options.map(option => (
                  <div key={option._id} className="flex items-center mb-1   text-[17px]">
                    <FontAwesomeIcon icon={faCheck} className="text-[#68D585] mr-2" />
                    <p className="text-white   text-[17px]">
                      {option.name.en}
                    </p>
                  </div>
                ))}
                <div className="flex items-center mb-1   text-[17px]">
                  {index == 0 ? <FontAwesomeIcon icon={faCheck} className="text-[#68D585] mr-2" /> : <FontAwesomeIcon icon={faTimes} className="text-[#949494] mr-2" />}
                  <p className="text-white   text-[17px]">
                    Video-Consultation
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col items-center mt-auto">
            <p className=" text-center mt-6 mb-5 text-white">
              SSL-secured payments.
            </p>
            <button
              className="w-full py-4 rounded-lg font-bold text-[1.4rem] bg-[#05BDFB] text-white "
              onClick={handleOrderClick}
            >
              Order
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceCards;
